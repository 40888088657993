<template>
  <section id="banner-inner-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="banner-details text-center">
                        <h2>About</h2>
                        <ul>
                            <li><a href="#!">Home</a></li>

                            <li><span>/</span>About</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
  </section>
  <!-- About Top Area Start -->
    <section id="about-top" class="py100 bg-color">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="about-img">
                        <h2>History begins in 2020 with the foundation</h2>
                        <p>
                            SlimLoigx is expert in providing scaleable and flexible customized AI & Machine-Learning solutions and Computer-Vision solutions based on latest techniques, technology and tools.
                        </p>
                        <p>
                            We help you select the most beneficial plan or service to make your IT headaches our IT solutions. We specialize in AI, Machine-Learning, Data Science and Computer-Vision and we provide real-time problem solutions.
                        </p>
                        <ul>
                            <li><i class="icofont-check-alt"></i>Licensed OCR tool</li>
                            <li>
                                <i class="icofont-check-alt"></i>Hadoop and other Big data platforms
                            </li>
                            <li><i class="icofont-check-alt"></i>Luigi workflow manager</li>
                            <li>
                                <i class="icofont-check-alt"></i>Distributed setup on Cloud for speed
                            </li>
                            <li><i class="icofont-check-alt"></i>Django framework</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="about-top-img animation-img-two">
                        <img src="@/assets/img/about-page.png" alt="img" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- About Top End -->

    <!-- Service-desc Area Start -->
    <section id="service-dec" class="py100">
      <div class="container">
          <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div class="service-dec-img animation-img-one">
                      <img src="@/assets/img/project-details/2.png" alt="img" />
                  </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div class="service-dec-right-side">
                      <div class="left-side-title">
                          <h3>Let's Get Started</h3>
                          <h2 class="pt10">
                              Our Vision
                          </h2>
                      </div>
                      <div class="service-dec-left-text">
                          <p>
                              We envisage emergence of Slimlogix as a Company which delivers smart AI based solution to it’s clients worldwide and to greatly expand the capacity to implement data science, machine learning and artificial intelligence research to build range of products
                              and services.
                          </p>

                          <a href="contact.html" class="btn btn-theme">Contact Us</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
</template>

<script>
  export default {
    name: 'About',

  }
</script>